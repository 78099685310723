import { Col, Row } from 'antd';
import AppCarousel from '../app-carousel/app-carousel';
import AppMainInfo from '../app-main-info';


const MainPage = () => (
    <>
        <Row>
            <Col span={24}>
                <AppCarousel/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <AppMainInfo/>
            </Col>
        </Row>
    </>
);
export default MainPage;