import { Card, Col, Image, Button} from 'antd';
import { useNavigate } from "react-router-dom";
import './app-card-product.css';
import noPhoto from '../static/no-photo.png';

function AppCardProduct({ id, title, pictId }) {

    const nav = useNavigate();

    pictId > 0 ? pictId = `https://rest.general-trucks.ru/get_image/?file_id=${pictId}`: pictId = noPhoto;

    return (
        <Col className='product__col'>
            <Card
                hoverable
                className='product__card' 
                onClick={() => { 
                    nav(`/product/${id}`) 
                }} 
                cover={
                    <Image 
                        preview={false}
                        className='product__img' 
                        src = {pictId} 
                        alt = {title} 
                    />
                }
            >
                <div>
                    <p>{title}</p>
                </div>
                <Button 
                    className='product__button' 
                    type='primary'>
                    Подробнее
                </Button>
            </Card>
        </Col>
    );
};

export default AppCardProduct;