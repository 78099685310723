import React, { useEffect, useState, useMemo } from 'react';
import {GeneralService} from '../../service';
import AppCardProduct from '../app-card-product';
import './app-cards.css';

function AppCards({countCards, groupsID, excludeID = null }) {
    const api = useMemo(() => new GeneralService(), []);
    const [cars, setCars] = useState([]);
    const [carsInStock, setCarsInStock] = useState([]);
    // const dafID = 114;
    // const scaniaID = 123;
    // const commercID = 130;
    // const trailersID = 117;

    useEffect(() => {
        groupsID.map((id) => {
            api.getProducts(id).then((cars) => {
                const filteredCars = cars
                    .filter(car => car.published === true)
                    .filter(car => car.id !== excludeID);
                setCars(prevCars => [...prevCars, ...filteredCars]);
            }); 
        });
    }, []);

    useEffect(() => {
        if (cars.length > 0) {
            const randomCars = [];
            const count = countCards || cars.length;
            while (randomCars.length < count && randomCars.length < cars.length) { 
                const randomIndex = Math.floor(Math.random() * cars.length);
                if (!randomCars.includes(cars[randomIndex])) {
                    randomCars.push(cars[randomIndex]);
                }
            }
            setCarsInStock(randomCars);
        }
    }, [cars]);

    return (
        
        <div className='cars'>
            {carsInStock.map((item, key) => (
                <AppCardProduct key={`${item.id}-${key}`} id={item.id} title={item.title_store} pictId={item.pictures_ids[0]} />
            ))}
        </div>
    );
}

export default AppCards;