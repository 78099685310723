import React, { useState } from 'react';
import { Button, Modal, Typography, ConfigProvider } from 'antd';
import FeedbackForm from '../feedback/feedback-form';
import './call-back-button.css'
import CloseIcon from './static/close-icon.svg'

const CallBackButton = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#F67828',
            },
            Modal: {
              contentBg: 'rgba(54,50,47, 1)',
            }
          },
        }}
      >
        <Button
          className='call-back-button'
          onClick={showModal}>
          заказать звонок
        </Button>
        <Modal
          className='modal-main'
          open={open}
          onCancel={handleCancel}
          width={1000}
          closeIcon={<img style={{ height: '20px', width: '20px' }} src={CloseIcon} />}
          footer={null}
        >
          <Typography.Title className='modal-title'
            style={{
              fontSize: 48
            }}
          >Заказать звонок</Typography.Title>
          <div className='modal-feedback'>
            <FeedbackForm />
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};
export default CallBackButton;