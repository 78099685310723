import { Carousel, Row, Col, Space, Typography } from 'antd';
import { Link } from "react-router-dom";
import './app-carousel.css';

const items = [
    {
        key: '1',
        title: 'Европейские седельные тягачи и полуприцепы для грузоперевозок',
        content1: 'новые и с пробегом',
        content2: 'в наличии и под заказ'
    },
]

function AppCarouselItem({ title, content1, content2 }) {
    return (
        <div className='carousel-item'>
            <Row className='content'>
                <Col>
                    <Space direction="vertical" className='carousel__info'>

                        <Typography.Title level={1}>{title}</Typography.Title>
                        <Typography.Text>{content1}</Typography.Text>
                        <Typography.Text>{content2}</Typography.Text>
                        <Link className='carousel__button' to="/categories">Подобрать технику</Link>
                    </Space>
                </Col>
            </Row>
        </div >
    )
};

function AppCarousel() {

    const sliders = items.map((item) => {

        const { key, ...itemProps } = item;
        return <AppCarouselItem key={key} {...itemProps} />
        
    });

    return (
        <div className='main-carousel'>
            <Carousel autoplay>
                {sliders}
            </Carousel>
        </div>
    );
};

export default AppCarousel;