import { Card, Col, Row, Image } from 'antd';

import './app-reviews.css';

import reviews_01 from './static/reviews_01.webp';
import reviews_02 from './static/reviews_02.webp';
import reviews_03 from './static/reviews_03.webp';
import reviews_04 from './static/reviews_04.webp';
import reviews_05 from './static/reviews_05.webp';
import reviews_06 from './static/reviews_06.webp';
import reviews_07 from './static/reviews_07.webp';
import reviews_08 from './static/reviews_08.webp';

function AppReviews() {

    return (
    <>
        {window.innerWidth < 650 ? (
            <div className="horizontal-gallery reviews">
                <Row wrap={false}>
                    <Col>
                        <Image preview={false} src={reviews_01} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_02} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_03} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_05} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_04} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_08} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_06} />
                    </Col>
                    <Col>
                        <Image preview={false} src={reviews_07} />
                    </Col>
                </Row>
            </div>
        ) : (
            <Row className='content reviews'>
                <Row gutter={95} className='reviews1'>
                    <Col>
                        <Image src={reviews_01} />
                    </Col>
                    <Col>
                        <Image src={reviews_02} />
                    </Col>
                    <Col>
                        <Image src={reviews_03} />
                    </Col>
                </Row>
                <Row gutter={[40, 48]} className='reviews2'>
                    <Col span={6}>
                        <Image src={reviews_05} />
                    </Col>
                    <Col span={6}>
                        <Image src={reviews_04} />
                    </Col>
                    <Col span={5} className='reviews-small'>
                        <Row>
                            <Image src={reviews_08} style={{ height: '155px' }} />
                        </Row>
                        <Row>
                            <Image src={reviews_06} style={{ height: '135px' }} />
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Image src={reviews_07} />
                    </Col>
                </Row>
            </Row>
        )}
    </>
    );
}

export default AppReviews;