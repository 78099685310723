import axios from "axios";
const general_service_axios = axios.create({
    baseURL: 'https://rest.general-trucks.ru',
    timeout: 20000,
});
const armor_back_axios = axios.create({
    baseURL: 'https://back.armor-oil.ru',
    timeout: 20000,
});

export class ArmorService {
    async sendRequest( request) {
        try {
            const response = await armor_back_axios.post("/api/request/", { request },);
            return response.data.results;
        } catch (error) {
            console.log(error.toJSON());
        }
    }
}
export class GeneralService {
    async getResource(url, params) {
        try {
            const response = await general_service_axios.get(url, { params },);
            return response.data.results;
        } catch (error) {
            console.log(error.toJSON());
        }
    }
    getAllCategories() {
        const params = {
            "status": 1,
            "published": true,
            "published_count_gte": 1,
            "ordering": "kod",
            "roditel_id": 112,
        }
        return this.getResource('/spravochniki/gruppi_mas/', params)
    }
    getProducts(categoryID) {
        const params = {
            "limit": 10000,
            "status": 1,
            "offset": 0,
            "published": true,
            "ordering": "title_store",
            "groups_store_exact": categoryID,
        }
        return this.getResource('/spravochniki/mas/', params)
    }
    getProduct(product_id) {
        const params = {
            "id": product_id,
        }
        return this.getResource('/spravochniki/mas/', params)
    }
}
