import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Image, Breadcrumb, Typography, Carousel } from 'antd';

import { GeneralService } from '../../service';
import './app-product.css';

import AppSpinner from '../app-spinner';
import noPhoto from '../static/no-photo.png';
import AppCards from '../app-cards/app-cards';
import AppClients from '../app-clients/app-clients';
import AppTitle from '../app-title/app-title';
import Feedback3 from '../feedback/feedback3';
import FeedbackForm  from '../feedback/feedback-form';

const AppProduct = ({ id }) => {
    const api = useMemo(() => new GeneralService(), []);

    const [productData, setProductData] = useState({
        id: null,
        productName: '',
        productPrice: 0,
        productDescription: '',
        productPict: 0,
        productPictures: [],
        productIdGroup: 0,
        loading: true
    });

    useEffect(() => {
        const fetchData = async () => {
            const productResponse = await api.getProduct(id);

            if (productResponse.length > 0) {
                const product = productResponse[0];

                const pictures = product.pictures_ids.map((image, index) => ({
                    src: `https://rest.general-trucks.ru/get_image/?file_id=${image}`,
                    opacity: index === 0 ? 1 : 0.5
                }));
    
                const nameFormated = product.title_store.replace(/(\d{4})\s(г\.)/g, "$1\u00A0$2");
    
                const description = product.desc_store !== ''
                    ? product.desc_store
                    : '<span>Описание временно отсутствует</span>';
    
                const priceFormated = (product.rekomend_cena_za_ed + "")
                    .split("")
                    .reverse()
                    .join("")
                    .replace(/(\d{3})/g, "$1 ")
                    .split("")
                    .reverse()
                    .join("")
                    .replace(/^ /, "")
                    .split(".")[0];
                
                const productIdGroup = JSON.parse(product.gruppi_json_store).pop();

                setProductData(prevData => ({
                    ...prevData,
                    id: product.id,
                    productName: nameFormated,
                    productPrice: priceFormated,
                    productDescription: description,
                    productPictures: pictures,
                    productIdGroup: productIdGroup,
                    loading: false,
                }));
            }
        };

        fetchData();
    }, [api, id]);

    const handleImageClick = (index) => {
        const updatedPictures = productData.productPictures.map((picture, i) => ({
            ...picture,
            opacity: i === index ? 1 : 0.5
        }));

        setProductData(prevData => ({
            ...prevData,
            productPict: index,
            productPictures: updatedPictures
        }));
    };

    const carouselRef = React.createRef();

    if (productData.loading) {
        return <AppSpinner className='loading' />;
    }
        
    return (
        <>
            <Breadcrumb
                className='content breadCrumb'
                items={[
                    {
                        title: <Link to="/">Главная</Link>,
                    },
                    {
                        title: <Link
                        to="/categories"
                        >Каталог</Link>,
                    },
                    {
                        title: productData.productName,
                    },
                ]}
                separator="/"
            />
            {window.innerWidth < 750 ? (
                <Row className='content'>
                    <Row className='product__name'>
                        <h1>{productData.productName}</h1>
                    </Row>
                    <div className="horizontal-gallery product__gallery">
                        <Row className='product__gallery__photos' >
                            {productData.productPictures.map((picture, index) => (
                                <Col className='product__gallery__image'>
                                    <Image
                                        key={index}
                                        src={picture.src}
                                        alt='photo'
                                        preview={true}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <Row className='brief__info'>
                        <p>{productData.productPrice} ₽ <span style={{ opacity: 0.5 }}>с НДС</span></p>
                        <p>Автомобиль в наличии, в г. Тольятти</p>
                    </Row>
                </Row>
            ) : (
                <Row className='product__info'>
                    <Col className='all-photos__info'>
                        <Carousel 
                            dots={false} 
                            arrows 
                            afterChange={handleImageClick} 
                            className="product__carousel"
                            ref={carouselRef}
                        >
                            {productData.productPictures.map((image, index) => (
                                <div key={index}>
                                    <img src={image.src} alt={`Image ${index}`} />
                                </div>
                            ))}
                        </Carousel>
                        <div className='product__photos'>
                            {productData.productPictures.map((picture, index) => (
                                <Image
                                    key={index}
                                    src={picture.src}
                                    onClick={() => carouselRef.current.goTo(index, false)}
                                    alt='photo'
                                    preview={false}
                                    style={{ opacity: picture.opacity }}
                                />
                            ))}
                        </div>
                    </Col>

                    <Col className='brief__info'>
                        <h1>{productData.productName}</h1>
                        <p>{productData.productPrice} ₽ <span style={{ opacity: 0.5 }}>с НДС</span></p>
                        <p>Автомобиль в наличии, в г. Тольятти</p>
                        <p>Отправьте заявку<br />для <span style={{ color: '#F67828' }}>бесплатной</span> консультации</p>
                        <div style={{width:'60%'}}><FeedbackForm /></div>
                    </Col>
                </Row>
                
            )}

            <Typography.Title key={'description'} className='product__h3 content' editable level={3} >
                Описание
            </Typography.Title>
            <Row gutter={80} className='content description'>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: productData.productDescription }}></div>
                </Col>
                <Col>
                    <p>Работаем в лизинг. Аванс от 1%.<br />Любая форма оплаты<br />Цена с НДС</p>
                    <p>Доставляем технику до наших ДЦ в Тольятти, Набережных Челнах, Казани, Екатеринбурге и Уфе.</p>
                    <p>Предоставляем полный спектр услуг по продаже надежных грузовых автомобилей Европейского производства.</p>
                    <p>Большинство автомобилей находятся в г.Тольятти.<br />Оказываем услуги по выкупу и доставке техники из Европы.<br />Поможем подобрать технику по вашим характеристикам.</p>
                </Col>
            </Row>
            <Row className='notification content'>
                <p>Вся представленная на сайте информация, касающаяся наличия на складе, технических характеристик, фотографий техники, носит информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями пункта 2 статьи 437 Гражданского кодекса РФ. Заполнение заявок не накладывает на владельцев сайта никаких обязательств, кроме обеспечения сохранности персональных данных. Эксплуатационные показатели не являются конечными и зависят от выбранной комплектации транспортного средства. Завод-изготовитель оставляет за собой право изменять технические характеристики техники, а также перечень применяемых для ее изготовления комплектующих и их параметры, если указанные решения не изменяют ее назначение.</p>
            </Row>
            <AppTitle text={'Похожие авто'} />
            <Row className='content'>
                <AppCards
                    key={productData.id}
                    countCards={4}
                    groupsID={[productData.productIdGroup]}
                    excludeID={productData.id} />
            </Row>
            <Row className='content'>
                <Col className='button-all-goods'>
                    <Button><Link to="/categories">Посмотреть все</Link></Button>
                </Col>
            </Row>
            <AppTitle text={'Сопутствующая техника'} />
            <Row className='content'>
                <AppCards
                    key={productData.id}
                    countCards={4}
                    groupsID={productData.productIdGroup === 117 ? [114, 123, 130] : [117]}
                />
            </Row>
            <Row className='content'>
                <Col className='button-all-goods'>
                    <Button><Link to="/categories">Посмотреть все</Link></Button>
                </Col>
            </Row>
            <AppTitle text={'Наши клиенты'} />
            <Row>
                <AppClients />
            </Row>
            <Row><Feedback3 /></Row>
        </>
    );
};

export default AppProduct