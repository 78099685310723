import React from 'react';
import { useParams } from 'react-router-dom';
import AppProduct from '../app-product';

const ProductPage = () => {
  const {id}  = useParams();
  
  return (
      <AppProduct key={id} id={id} />
  );
}

export default ProductPage;