import React from 'react';
import { Breadcrumb, Typography, Col, Row } from 'antd';
import { Link } from "react-router-dom";

import {GeneralService} from '../../service';
import './app-categories.css';

import AppSpinner from '../app-spinner';
import AppCarousel from '../app-carousel/app-carousel';
import AppClients from '../app-clients/app-clients';
import AppTitle from '../app-title/app-title';
import AppCards from '../app-cards/app-cards';
import AppMap from '../app-map/app-map';
import { Feedback, Feedback2 } from '../feedback';

class AppCategoriesSection extends React.Component {
    api = new GeneralService();
    id = 0;
    name = 'noName';
    state = {
        products: [],
        loading: true
    }

    constructor(props){
        super(props);
        this.id = props.id;
        this.name = props.name;
    }

    componentDidMount() {
        const { id } = this.props;
        this.api.getProducts(id).then((res) => {
            this.setState({
                products: res,
                loading: false
            });
        });
    }

    render() {
        if (this.state.loading) {
            return <AppSpinner />;
        }

        return (
            <div className='categories__section content'>
                <Typography.Title editable level={3}>{this.name}</Typography.Title>
                <AppCards groupsID={[this.id]} />
            </div>
        );
    }
};

class AppCategoriesAll extends React.Component {
    api = new GeneralService();
    state = {
        categories: []
    }

    componentDidMount() {
        this.api.getAllCategories().then((res) => {
            this.setState({ 
                categories: res
            });
        });
    }

    render() {

        const categories_item = this.state.categories.map((item) => {
            return (
                <AppCategoriesSection key={item.id} id={item.id} name={item.polnoe_nazvanie} />
            );
        });

        return (
            <div>
                {categories_item}
            </div>
        )
    }
};

function AppCategories() {

    return (
        <>
            <Breadcrumb
                className='content breadCrumb'
                items={[
                    {
                        title: <Link to="/">Главная</Link>,
                    },
                    {
                        title: 'Каталог'
                    }
                ]}
                separator="/"
            />
            <Row className='categories__carousel'>
                <Col span={24}>
                    <AppCarousel />
                </Col>
            </Row>
            {/* <AppTitle text={'Подбор техники'} /> */}
            <AppTitle text={'Каталог'} />
            <AppCategoriesAll />
            <AppTitle text={'Наши центры'} />
            <AppMap />
            <AppTitle text={'Клиенты'} />
            <AppClients />
            <Feedback2 />
        </>
    )
};

export default AppCategories;
