import React, { Component } from 'react';
import { Button, Form, Input, Row, Col, Typography, Checkbox } from 'antd';
import InputMask from 'react-input-mask';
import { ArmorService } from '../../service';
import './feedback.css'
import FeedbackForm from './feedback-form';



/* eslint-disable no-template-curly-in-string */
// const validateMessages = {
//     required: 'Требуется ввести ${label}!',
//     types: {
//         phone: '${label} введен некорректно!',
//     }
// };
/* eslint-enable no-template-curly-in-string */



const PhoneInput = ({ onChange, val }) => {

    return (
        <InputMask
            mask="+7 (999) 999-99-99"
            maskChar="_"
            onChange={onChange}
            value={val}
        >
            {() => <Input placeholder="Ваш телефон" />}
        </InputMask>
    );
};

const NameUserInput = ({ onChange, val }) => {
    return (
        <Input
            placeholder="Ваше Имя"
            value={val}
            onChange={onChange} />
    );
};

class Feedback2 extends Component {
    api = new ArmorService();
    constructor(props) {
        super(props);
        this.state = {
            nameUser: '',
            phone: ''
        };
    }
    onFinish = (value) => {
        this.api.sendRequest({
            "phone": this.state.phone,
            "name": this.state.nameUser,
            "status": "NEW"
        })
        this.setState({
            nameUser: '',
            phone: ''
        });
    };

    handleNameUserChange = (event) => {
        console.log('handleNameUserChange', event.target.value);
        this.setState({ nameUser: event.target.value });
    }

    handlePhoneChange = (event) => {
        console.log('handlePhoneChange', event.target.value);
        this.setState({ phone: event.target.value });
    }

    render() {
        return (
            <Row className='feedback content' id='feedback'
                justify={'space-between'}
                align={'middle'}>
                <Col span={10} className='feedback__text'>
                    <Typography.Text>
                        Напишите свои контакты<br /> и мы
                        <span style={{ color: '#F67828' }}> вам перезвоним </span>
                    </Typography.Text>
                </Col>
                <Col span={7} className='feedback__form-style'>
                   <FeedbackForm />
                </Col>
            </Row>
        )
    }
}
export default Feedback2;

