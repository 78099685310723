import './app-spinner.css';

const AppSpinner = () =>{
   return  (
        <div className="loader">
			{/* <span style={{"--i":"1"}}></span>
			<span style={{"--i":"2"}}></span>
			<span style={{"--i":"3"}}></span>
			<span style={{"--i":"4"}}></span>
			<span style={{"--i":"5"}}></span>
			<span style={{"--i":"6"}}></span>
			<span style={{"--i":"7"}}></span>
			<span style={{"--i":"8"}}></span> */}
			<span className="rotate" style={{"--r":"0"}}></span>
			<span className="rotate" style={{"--r":"1"}}></span>
			<span className="rotate" style={{"--r":"2"}}></span>
			<span className="rotate" style={{"--r":"3"}}></span>
			<span className="rotate" style={{"--r":"4"}}></span>
		</div>
    )
};

export default AppSpinner;