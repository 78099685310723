import { Image, Row } from 'antd';
import './app-map.css';
import map from './static/map.jpg';

function AppMap() {

    return (
        <Row className='map'>
            <div className='shadow_for_map_top'></div>
            <Image preview={false} src={map} />
            <div className='shadow_for_map_bot'></div>
        </Row>
    );
}

export default AppMap;